<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`CV database`) }}</h1>
                          <b-dropdown
                              v-if="iCan( 'cv', 'write' )"
                              id="add-new-dropdown"
                              variant="primary"
                              class="float-right"
                              right
                          >
                            <template #button-content>
                              <feather-icon icon="PlusCircleIcon" />
                              {{ i18nT(`Add new`) }}
                            </template>

                            <b-dropdown-item>
                              <b-link :to="{ name: 'create-cv' }">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'user-plus']" />
                                {{ i18nT(`Add`) }}
                              </b-link>
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-b-modal.modal-import-csv
                                >
                              <div class="text-primary">
                                <font-awesome-icon class="mr-25" :icon="['fas', 'file-import']" />
                                {{ i18nT(`Import`) }}
                              </div>
                            </b-dropdown-item>

                          </b-dropdown>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                    </div>
                </div>
                <b-row>

                    <b-col
                        cols="12"
                        md="6"
                        v-if="controlVisible"
                    >
                        <b-form-group
                            :label="i18nT(`Skills`)"
                            label-for="matchSkills"
                        >
                            <v-select
                                v-model="matchSkill"
                                :options="matchSkills"
                                :placeholder="i18nT(`All`)"
                                label="title"
                                multiple
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                        v-if="controlVisible"
                    >
                        <b-form-group
                            :label="i18nT(`Filter by label`)"
                            label-for="jobOffers"
                        >
                            <v-select
                                v-model="filterLabels"
                                :options="labelOptions"
                                :placeholder="i18nT(`All`)"
                                label="title"
                                multiple
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                class="per-page-selector d-inline-block"
                            />

                            <b-dropdown
                                v-if="selectedItems.length > 0"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                class="d-inline ml-1"
                                size="md"
                                text="Actions"
                                variant="outline-secondary"
                            >
                                <b-dropdown-item v-if="iCan('cv', 'write')" @click="addLabel" >
                                    <feather-icon icon="TagIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Add label`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="onBulkPropose">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Offer a position`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="iCan('cv', 'write')"
                                    @click="onBulkDelete"
                                >
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        class="input-group input-group-merge"
                                        role="group"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            class="d-inline-block"
                                            type="text"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        v-model="currentItems"
                        :empty-html="emptyHtml"
                        :fields="computedColumns"
                        :items="fetchDocuments"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        primary-key="id"
                        responsive
                        show-empty
                        @refreshed="onTableRefresh"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.user.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                                <div class="media-aside align-self-center">
                                    <b-avatar
                                        :src="data.item.user.ImageUrl"
                                        :text="(data.item.user.FirstName ? data.item.user.FirstName.charAt(0) : '') + (data.item.user.LastName ? data.item.user.LastName.charAt(0) : '')"
                                        :to="{ name: 'cv-preview', params: { id: data.item.user.Id }}"
                                        class="badge-light-primary"
                                    >
                                    </b-avatar>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        :to="{ name: 'cv-preview', params: { id: data.item.user.Id } }"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.user.Label }}
                                    </b-link>
                                    <p class="mb-0">{{ data.item.user.Position }}</p>
                                </div>
                            </div>
                        </template>

                        <template #cell(labels)="data">
                            <div
                                v-for="(label, index) in getLabels(data.item.labels)"
                                :key="index"
                                class="badge text-capitalize badge-light-primary badge-pill mr-25"
                            >
                                {{ label.title }}
                            </div>
                        </template>

                        <template #cell(skills)="data">
                            <div
                                v-for="(skill, index) in data.item.skills"
                                :key="`skill-${index}`"
                                class="badge badge-light-secondary badge-pill mr-25">
                                {{ skill.Skill }}
                            </div>
                        </template>

                        <template #cell(created)="data">
                                {{ data.item.user.CreatedAt | date }}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                :right="$store.state.appConfig.isRTL"
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                            >
                                <template #button-content>
                                    <feather-icon
                                        class="align-middle text-body"
                                        icon="MoreVerticalIcon"
                                        size="16"
                                    />
                                </template>
                                <b-dropdown-item v-if="iCan('cv', 'write')"
                                                 :to="{ name: 'cv-edit', params: {id: data.item.user.Id}}"
                                >
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="iCan('cv', 'write')"
                                                 @click="addLabel(data)"
                                >
                                    <feather-icon icon="TagIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Add/Edit label`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :to="{ name: 'inbox-send', params: {id: data.item.user.Id, software: software}}">
                                    <feather-icon icon="SendIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Send message`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onPropose(data)">
                                    <feather-icon icon="MailIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Invite to position`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onAssign(data)">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Assign to position`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="iCan('cv', 'write')"
                                                 @click="onDelete(data)"
                                >
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </template>
                    </b-table>
                </div>
                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-start
                              "
                            cols="12"
                            sm="6"
                        >
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                            cols="12"
                            sm="6"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalDocuments"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL PROPOSE POSITION -->
        <b-modal
            id="modal-propose"
            no-close-on-backdrop
            ok-only
            :ok-title="i18nT(`Send`)"
            :title="i18nT(`Offer a Position`)"
            @ok="validationPropose"
        >
            <validation-observer ref="proposeForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Job Offer`)" class="required"
                                label-for="jobOffer"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Job Offer`)"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="proposeJob"
                                        :options="jobOffers"
                                        :state="errors.length > 0 ? false:null"
                                        text-field="title"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group
                                :label="i18nT(`Subject`)" class="required"
                                label-for="subject"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Subject`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="messageSubject"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group
                                :label="i18nT(`Message`)" class="required"
                                label-for="message"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Message`)"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        v-model="message"
                                        :state="errors.length > 0 ? false:null"
                                        rows="12"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL ASSIGN TO POSITION -->
        <b-modal
            id="modal-assign"
            no-close-on-backdrop
            ok-only
            :ok-title="i18nT(`Assign`)"
            :title="i18nT(`Assign to position`)"
            @ok="validationPropose"
        >
            <validation-observer ref="proposeForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Job Offer`)" class="required"
                                label-for="jobOffer"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Job Offer`)"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="proposeJob"
                                        :options="jobOffers"
                                        :state="errors.length > 0 ? false:null"
                                        text-field="title"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL CHANGE STATUS -->
        <b-modal
            id="modal-change-status"
            :ok-title="i18nT(`Change Status`)"
            :title="i18nT(`Change Status`)"
            no-close-on-backdrop
            ok-only
            @ok="validationChangeStatus"
        >
            <validation-observer ref="changeStatusForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">

                            <b-form-group v-if="targetStage.message_template">
                                <b-form-checkbox
                                    v-model="changeStatusNotification"
                                    :checked="changeStatusNotification"
                                    switch
                                >
                                    {{ i18nT(`Send notification email?`) }}
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                v-if="targetStage.message_template" :label="i18nT(`Message Template`)"
                                class="required"
                                label-for="messageTemplate"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Message Template"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="statusChangeTemplate"
                                        :options="[{value: targetStage.message_template.id, title: targetStage.message_template.label}]"
                                        :state="errors.length > 0 ? false:null"
                                        text-field="title"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL ADD LABEL TO CANDIDATE-->
        <b-modal
            id="modal-label"
            :ok-title="i18nT(`Save`)"
            :title="i18nT(`Add Label to the selection`)"
            no-close-on-backdrop
            ok-only
            @ok="validationLabels"
        >
            <validation-observer ref="labelForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <voerro-tags-input
                                v-model="selectedTags"
                                :existing-tags="tagsList"
                                :typeahead="true"
                                element-id="labels"
                                :placeholder="i18nT(`Add label`)"
                                @blur="onBlur"
                            ></voerro-tags-input>
                          <div class="pt-1">
                            <span class="text-muted " >{{i18nT(`Press enter to add new label`)}}</span>
                          </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL IMPORT CSV-->
        <modal-import-csv class-name="candidates" @imported="refetchData"/>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTabs,
    // BTab,
    // BCardFooter,
    // BCardBody,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    // BDropdownDivider,
    BLink,
    BAvatar,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCvList from './useCvList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArrayWithTitle} from '@core/utils/utils'
import router from '@/router'
// import _ from 'lodash'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import Ripple from 'vue-ripple-directive'
import ModalImportCsv from "@/views/common/ModalImportCsv/ModalImportCsv.vue";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BAvatar,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        //BTabs,
        //BTab,
        // BListGroup,
        // BListGroupItem,
        //BCardFooter,
        //BCardBody,
        vSelect,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormSelect,
        BFormInput,
        BFormTextarea,
        ModalImportCsv,
        // BDropdownDivider,
        VoerroTagsInput
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            jobOffers: [],
            matchSkills: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            sendInvitation: false,
            selectAllStatus: false,
            countries: {},
            folderOptions: [],
            labelOptions: [],
            folders: [],
            required,
            proposeJob: '',
            messageSubject: '',
            message: '',
            candidateId: '',
            removeFolderOptions: [],
            software: useAuth.getCurrentSoftware(),
            stages: [],
            targetStage: {},
            statusChangeTemplate: null,
            changeStatusNotification: false,
            selectedTags: [],
            tagsList: [],
            avalableFields: [
              {label: this.i18nT(`Skills`), key: 'skills', visible: true},
              {label: this.i18nT(`Labels`), key: 'labels', visible: true},
              {label: this.i18nT(`Created on`), key: 'created', visible: true},
            ],
            controlVisible: true,
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('cvList')
            if(storePreferences) {
              this.avalableFields = storePreferences
        }
        this.$http.get(`offers/filterOffers`).then(({data}) => {
            this.jobOffers = dictToSelectArrayWithTitle(data.data)
        })

        this.$http.get(`system/countries`).then(({data}) => {
          this.countries = data.data
        })

        this.$http.get(`offers/jobSkills`).then(({data}) => {
            this.matchSkills = data.data.map(type => ({
                value: type.id,
                title: type.label
            }))
        })


        this.$http.get(`settings/labels`).then(({data}) => {
            this.labelOptions = data.data.map(label => ({
                value: label.Id,
                title: label.Label
            }))
            this.tagsList = data.data.map(tag => ({
              key: tag.Id,
              value: tag.Label
            }))
          console.log(this.labelOptions);
        })

        this.$http.get(`candidates/stages`).then(({data}) => {
            this.stages = data.data
            this.stages.shift()
        })


        this.fetchDocuments(null, this.determineVisibility);
    },
    methods: {
        onBlur(event) {

          if(event && event.relatedTarget && event.relatedTarget.className && event.relatedTarget.className === "btn btn-primary") {
              this.selectedTags.push({key: '', value: event.target.value})
          }
        },
        getFilterOptions(){
          this.$http.get(`offers/jobSkills`).then(({data}) => {
            this.matchSkills = data.data.map(type => ({
              value: type.id,
              title: type.label
            }))
          })

          this.$http.get(`settings/labels`).then(({data}) => {
            this.labelOptions = data.data.map(label => ({
              value: label.Id,
              title: label.Label
            }))
            this.tagsList = data.data.map(tag => ({
              key: tag.Id,
              value: tag.Label
            }))
          })
        },
        determineVisibility(invoiceArray) {
          if(invoiceArray.length===0){
            this.controlVisible = false;
          }
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const document = this.documentTypes.find(type => type.value == typeId)
            return document ? document.text : ''
        },
        getAssigneeName(assignee) {
            if (assignee == null) {
                return ''
            }

            return assignee.Label
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`documents/copyDocument?document_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(cv) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.isConfirmed) {

                    this.$http
                        .delete(`candidates?id=`+cv.item.user.Id)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `documents/copyDocument?document_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.isConfirmed) {
                    this.$http
                        .delete(`candidates?id=${this.selectedItems.join(',')}`)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.user.Id)
            } else {
                this.selectedItems = []
            }
        },
        onBulkPropose() {
            this.candidateId = this.selectedItems.join(',')
            this.$bvModal.show('modal-propose')
        },
        onPropose(data) {
            this.sendInvitation = true;
            this.candidateId = data.item.user.Id
            this.$bvModal.show('modal-propose')
        },
        onAssign(data) {
            this.sendInvitation = false;
            this.candidateId = data.item.user.Id
            this.$bvModal.show('modal-assign')
        },
        validationPropose() {
            this.$refs.proposeForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('offer_id', this.proposeJob)
                    formData.append('candidate_ids', this.candidateId)
                    if(this.sendInvitation){
                        formData.append('subject', this.messageSubject)
                        formData.append('message', this.message)
                    }
                    formData.append('send_message', this.sendInvitation ? 1 : 0);


                    this.$http
                        .post('candidates/proposeOffer', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    text: this.i18nT(`Check your applications list for more details`),
                                    icon: 'InfoIcon',
                                    variant: 'success',
                                    timeout: 6000
                                },

                            })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        moveStage(stage) {
            this.targetStage = stage
            this.statusChangeTemplate = stage.message_template
                ? stage.message_template.id
                : null

            this.$bvModal.show('modal-change-status')
        },
        moveFolderBulk(folder) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: `Are you sure you want to change the folder ${folder.title}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append('candidates', this.getCandidateIds().join(','))
                    formData.append('folders', folder.value)
                    this.$http
                        .post('candidates/addToFolder', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.folders = []
                            this.$bvModal.hide('modal-add-to-folder')
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        validationChangeStatus(event) {
            event.preventDefault()
            this.$refs.changeStatusForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('application_id', this.selectedItems.join(','))
                    formData.append('stage', this.targetStage.short)
                    if (this.statusChangeTemplate) {
                        formData.append('message_template', this.statusChangeTemplate)
                    }
                    this.$http
                        .post('candidates/changeStage', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-change-status')
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        addLabel(data) {
          if(data.item && data.item.user && data.item.user.Id){
            this.selectedItems = [data.item.user.Id];
            let tags = []
            
            for (let [key, value] of Object.entries(data.item.labels)) {
                tags.push({value: value, key: key})
            }

            this.selectedTags = tags
          }
            this.tags = []
            this.$bvModal.show('modal-label')
        },
        validationLabels(event) {
            event.preventDefault()


                const tags = this.selectedTags
                    .reduce((acc, tagg) => {
                        if(tagg.key) {
                            acc.push(tagg.key)
                        } else if (tagg.value) {
                            acc.push(tagg.value)
                        }
                        return acc
                    }, [])
                    .join(',')

                const formData = new FormData()
                formData.append('freelancers', this.getCandidateIds().join(','))

                if(tags) {
                    formData.append('labels', tags)
                }

                this.$http
                    .post('candidates/updateLabels', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })

                        this.selectedTags = []
                        this.getFilterOptions();
                        this.refetchData()
                        this.$bvModal.hide('modal-label')
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
        },
        getCandidateIds() {
            return this.cvs
                .filter(cv => this.selectedItems.includes(cv.user.Id))
                .map(cv => cv.user.Id)
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            filterLabels,
            matchSkill,
            cvs,
            refetchData
        } = useCvList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            filterLabels,
            matchSkill,
            avatarText,
            cvs,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your cv database. No cv has been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/re/cvs/create\"'>" + this.i18nT(`Create cv`) + "</button>"
        },
        computedColumns() {

              let col = [...this.tableColumns]

              for(let i = 0; i < this.avalableFields.length; i++) {
                if(!this.avalableFields[i].visible) {
                  col = col.filter(c => c.key !== this.avalableFields[i].key)
                }
              }
              useAuth.setColumnPreferences('cvList', this.avalableFields);

              return col
        },
    },
    watch: {
        proposeJob: function (val) {
            const jobOffer = this.jobOffers.find(offer => offer.value === val)
            this.message = `Dear [candidate_name],

We would like to propose you the following job position(s) with us:
${window.location.protocol}//${
                window.location.host
            }/apply/${jobOffer.title.split(' ').join('-')}_${jobOffer.value}

Sincerely
Ilya Popivanov
Dea Solutions International`
            this.messageSubject = jobOffer.title
        }
    },
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type='text'] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: 0.5em 0.25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: 0.25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before,
.tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;

    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}

.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}

.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: 0.5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: 0.25em 1em;
    cursor: pointer;
}
</style>
