import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useCvList(isTemplate) {
    const refDocumentListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
        { key: 'name', label: i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
        { key: 'skills', label: i18nT(`Skills`), sortable: true },
        { key: 'labels', label: i18nT(`Labels`), sortable: true },
        { key: 'created', label: i18nT(`Created on`), sortable: true },
        {
            key: 'action',
            label: i18nT(`Actions`),
            thClass: 'tbl-actions',
            tdClass: 'sticky-column',
            stickyColumn: true
        },
    ];
    const perPage = ref(10);
    const totalDocuments = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('created');
    const isSortDirDesc = ref(true);
    const filterLabels = ref(null);
    const matchSkill = ref(null);
    const matchFolder = ref(null);
    const cvs = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refDocumentListTable.value ?
            refDocumentListTable.value.localItems.length :
            0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDocuments.value,
        };
    });

    const refetchData = () => {
        refDocumentListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            filterLabels,
            matchSkill,
            isSortDirDesc,
            matchFolder,
            sortBy,
        ],
        () => {
            refetchData();
        }
    );

    const fetchDocuments = (ctx, callback) => {
        const curWorkspace = useAuth.getCurWorkspace();

        const params = {
            page: currentPage.value,
            show_per_page: perPage.value,
            sort_by: sortBy.value,
            sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
            search: searchQuery.value
            //company_id : curWorkspace.Id,
        };

        if (isTemplate) {
            params.templates = 1;
        }

        if (filterLabels.value != null) {
            params['filters[labels]'] = filterLabels.value.map(v => v.value);
        }
        if (matchSkill.value != null) {
            params['filters[skills]'] = matchSkill.value.map(v => v.value);
        }
        if (matchFolder.value != null) {
            params['filters[folder]'] = matchFolder.value.value;
        }

        axios
            .get(`candidates`, {
                params,
            })
            .then(({ data }) => {
                cvs.value = data.data.records;
                callback(data.data.records);
                totalDocuments.value = data.data.pagination.records_count;
            });
    };

    return {
        fetchDocuments,
        tableColumns,
        perPage,
        currentPage,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,
        totalDocuments,
        filterLabels,
        matchSkill,
        matchFolder,
        cvs,
        refetchData,
    };
}
